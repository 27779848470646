import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory, useParams } from 'react-router'
import AgentService from './service/agentService'
import UserBean from '../../sharePortal/userManagement/bean/userBean'
import CommissionAccordionComponent from '../../component/commission/CommissionAccordionComponent'
import UserRole from '../../common/constant/userRole'
import { CommissionQuota } from './bean/commissionQuota'
import { Alert, AlertTitle, TabContext, TabPanel } from '@material-ui/lab'
import AgentPrincipleComponent from '../../component/agent/AgentPrincipleComponent'
import userProfileService from '../../common/service/userProfileService'
import NricTextField from '../../component/inputFields/nricTextField'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import BankSelectField from '../../component/inputFields/bankSelectField'
import StateSelectField from '../../component/inputFields/stateSelectField'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import { AgentPatchBody } from './bean/agentPatchBody'
import { AlertDialogAction } from '../../redux/reducer/alertDialogReducer'
import { AuthenticationService } from '../../preLogin/service/authenticationService'
import ChangeSuperiorDialog from './dialog/changeSuperior.dialog'
import SubAgentTreeComponent from '../../component/agent/subAgentTree.component'
import UserActionsMenuComponent from './component/userActionsMenu.component'
import AgentWithdrawalTab from './agentWithdrawal.tab'
import AgentClaimTab from './agentClaim.tab'
import { UserProfileAttachemntService } from '../../preLogin/service/userProfileAttachemntService'
import UserProfileAttachmentBean from '../../preLogin/bean/userProfileAttachmentBean'
import UserProfileAttachmentTable from '../../common/component/userProfileAttachmentTable'
import { OrderUploadFileKey } from '../../common/orderUtils'
import DateUtils from '../../common/dateUtils'
import { useAppDispatch } from '../../redux/store'

const PageTabs = {
  PROFILE: { key: 'PROFILE', display: 'Profile' },
  COMMISSIONS: { key: 'COMMISSIONS', display: 'Commissions Rate' },
  SUB_AGENT: { key: 'SUB_AGENT', display: 'Sub Agents' },
  WITHDRAWALS: { key: 'WITHDRAWALS', display: 'Withdrawals' },
  CLAIMS: { key: 'CLAIMS', display: 'Claims' },
  ATTACHMENTS: { key: 'ATTACHMENTS', display: 'Attachments' },
}

function groupCommissionQuotaByCategoryName(
  all: Record<string, CommissionQuota[]>,
  cur: CommissionQuota
): Record<string, CommissionQuota[]> {
  const categoryCommissions = all[cur.categoryName] || []

  return {
    ...all,
    [cur.categoryName]: [...categoryCommissions, cur],
  }
}

type AgentDetailPageReducerProps = {
  tabIndex: string
  haveUnsetPercentage: boolean
  commissions: Record<string, CommissionQuota[]>
}

type Action =
  | { type: 'Set Tab Index'; payload: string }
  | { type: 'Set Have Unset Commission'; payload: boolean }
  | { type: 'Set Commissions'; payload: Record<string, CommissionQuota[]> }

const AgentDetailPageReducerInitialState: AgentDetailPageReducerProps = {
  tabIndex: PageTabs.PROFILE.key,
  haveUnsetPercentage: false,
  commissions: {},
}
const agentDetailPageReducerProps = (
  state: AgentDetailPageReducerProps,
  action: Action
): AgentDetailPageReducerProps => {
  switch (action.type) {
    case 'Set Tab Index':
      return {
        ...state,
        tabIndex: action.payload,
      }
    case 'Set Have Unset Commission':
      return {
        ...state,
        haveUnsetPercentage: action.payload,
      }
    case 'Set Commissions':
      return {
        ...state,
        commissions: action.payload,
      }
    default:
      return state
  }
}

const AgentDetailPage = () => {
  const disabled = useMemo(
    () => !userProfileService.isManager() && !userProfileService.isAdmin(),
    []
  )

  const [isChangeSuperiorOpen, setIsChangeSuperiorOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const [originalAgent, setOriginalAgent] = useState<UserBean>()
  const [agent, setAgent] = useState<UserBean>()
  const [principle, setPrinciple] = useState<UserBean>()

  const [pageState, pageDispatch] = useReducer(
    agentDetailPageReducerProps,
    AgentDetailPageReducerInitialState
  )

  useEffect(() => {
    setPrinciple(undefined)
    AgentService.findById(id).then(resp => setOriginalAgent(resp.data))
  }, [id, refresh])

  useEffect(() => {
    if (originalAgent?.superiorId) {
      AgentService.findById(originalAgent?.superiorId).then(resp =>
        setPrinciple(resp.data)
      )
    } else {
      setPrinciple(undefined)
    }
  }, [originalAgent])
  useEffect(() => {
    setAgent(originalAgent)
  }, [originalAgent])

  useEffect(() => {
    const { id: agentId, role } = originalAgent || {}
    if (agentId && UserRole.isAgent(role)) {
      AgentService.getAgentCommission(agentId, undefined).then(value => {
        pageDispatch({
          type: 'Set Have Unset Commission',
          payload: value.data.some(value => value.percentage <= 0),
        })

        pageDispatch({
          type: 'Set Commissions',
          payload: value.data.reduce<Record<string, CommissionQuota[]>>(
            groupCommissionQuotaByCategoryName,
            {}
          ),
        })
      })
    }
  }, [originalAgent])

  function resetAgent() {
    AgentService.findById(id)
      .then(resp => setOriginalAgent(resp.data))
      .catch(err => {
        catchErrorWithDispatch(dispatch)(err)
        history.goBack()
      })
  }

  function onTextChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = e.target
    handleAgentChange(name, value)
  }

  function onSelectChange(
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const { name, value } = e.target
    if (name) {
      handleAgentChange(name, value)
    }
  }

  function handleAgentChange(name: string, value: any) {
    if (agent) {
      setAgent({
        ...agent,
        [name]: value,
      })
    }
  }

  function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation()
    event.preventDefault()

    const trimmed = _.mapValues(agent, function (param) {
      return _.isString(param) ? _.trim(param) : param
    })

    const newAgentPair = _.differenceWith(
      _.entries(trimmed),
      _.entries(originalAgent),
      _.isEqual
    )

    if (_.isEmpty(newAgentPair)) {
      dispatch(SnackbarAction.open('No New Value'))
    } else {
      const agentPatchBody = _.fromPairs(newAgentPair)

      dispatch(LoadingAction.open('Updating Agent...'))
      AgentService.patchAgent(id, agentPatchBody as AgentPatchBody)
        .then(() => {
          dispatch(SnackbarAction.open('Success Update Agent detail'))
          resetAgent()
        })
        .catch(catchErrorWithDispatch(dispatch))
        .finally(() => dispatch(LoadingAction.close()))
    }
  }

  function handleVerifyAgent() {
    dispatch(
      AlertDialogAction.open(
        `Verify Agent`,
        `Are you sure to verify ${agent?.fullName}`,
        () => {
          if (agent?.id) {
            AuthenticationService.verifyUser(agent?.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Verify Agent'))
                resetAgent()
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  function handleVerifyAgentAttachment() {
    dispatch(
      AlertDialogAction.open(
        `Verify Agent Attachment`,
        `Are you sure to verify ${agent?.fullName}`,
        () => {
          if (agent?.id) {
            AuthenticationService.verifyUserAttachment(agent?.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Verify Agent Attachment'))
                resetAgent()
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  function handleDisableAgent() {
    dispatch(
      AlertDialogAction.open(
        `Disable Agent`,
        `Are you sure to disable ${agent?.fullName}`,
        () => {
          if (agent?.id) {
            AuthenticationService.disableUser(agent?.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Disable Agent'))
                resetAgent()
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  function handleDeleteAgent() {
    dispatch(
      AlertDialogAction.open(
        <div className='text-red-500 font-bold'>Delete Agent</div>,
        <div>
          Are you sure to <span className='text-red-500 font-bold'>DELETE</span>{' '}
          {agent?.fullName}
        </div>,
        () => {
          if (agent?.id) {
            AuthenticationService.deleteUser(agent.id)
              .then(() => {
                dispatch(SnackbarAction.open('Success Delete Agent'))
                resetAgent()
              })
              .catch(catchErrorWithDispatch(dispatch))
          }
        }
      )
    )
  }

  return (
    <TabContext value={pageState.tabIndex}>
      <AppBar position='relative'>
        <Toolbar className='flex justify-between'>
          <div className='space-x-3 flex items-center'>
            <IconButton color='inherit' onClick={history.goBack}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='h6' noWrap>
              Agent Detail
            </Typography>
          </div>

          <UserActionsMenuComponent
            user={originalAgent}
            handleDisableAgent={handleDisableAgent}
          />
        </Toolbar>

        <Tabs
          value={pageState.tabIndex}
          onChange={(event, value) => {
            pageDispatch({
              type: 'Set Tab Index',
              payload: value,
            })
          }}
        >
          <Tab label={PageTabs.PROFILE.display} value={PageTabs.PROFILE.key} />
          <Tab
            label={PageTabs.COMMISSIONS.display}
            value={PageTabs.COMMISSIONS.key}
          />
          <Tab
            label={PageTabs.SUB_AGENT.display}
            value={PageTabs.SUB_AGENT.key}
          />
          <Tab
            label={PageTabs.WITHDRAWALS.display}
            value={PageTabs.WITHDRAWALS.key}
          />
          <Tab label={PageTabs.CLAIMS.display} value={PageTabs.CLAIMS.key} />
          <Tab
            label={PageTabs.ATTACHMENTS.display}
            value={PageTabs.ATTACHMENTS.key}
          />
        </Tabs>
      </AppBar>
      {!agent?.enabled && (
        <Alert
          variant='standard'
          severity='error'
          action={
            <div className='space-x-2'>
              <Button
                variant={'outlined'}
                type={'button'}
                color={'secondary'}
                onClick={handleDeleteAgent}
              >
                Delete Agent
              </Button>
              <Button
                variant={'contained'}
                type={'button'}
                color={'secondary'}
                onClick={handleVerifyAgent}
              >
                Verify Agent
              </Button>
            </div>
          }
        >
          <AlertTitle>Disabled</AlertTitle>
          Agent not yet <span className='font-bold'>Verify Email</span>
        </Alert>
      )}

      {!agent?.attachmentVerifiedDate && (
        <Alert
          variant='standard'
          severity='error'
          action={
            <div className='space-x-2'>
              <Button
                variant={'contained'}
                type={'button'}
                color={'secondary'}
                onClick={handleVerifyAgentAttachment}
              >
                Verify Agent Attachment
              </Button>
            </div>
          }
        >
          <AlertTitle>Disabled</AlertTitle>
          Agent not yet <span className='font-bold'>Verify Attachment</span>
        </Alert>
      )}

      <TabPanel value={PageTabs.PROFILE.key}>
        {agent && principle && (
          <div className='mx-8'>
            <AgentPrincipleComponent
              principle={principle}
              selectedAgent={agent}
            />
          </div>
        )}

        <form onSubmit={handleOnSubmit}>
          <div className='mx-8 my-4'>
            <Card>
              <CardHeader title={'Profile'} />
              <CardContent className='grid-cols-2 gap-4 grid'>
                <TextField
                  label={'Username'}
                  name={'username'}
                  value={agent?.username}
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  label={'Full Name'}
                  name={'fullName'}
                  value={agent?.fullName}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                  required={true}
                />

                <NricTextField
                  label={'NRIC'}
                  name='nric'
                  value={agent?.nric}
                  onChange={onTextChange}
                  shrink={true}
                  disabled={disabled}
                />

                <TextField
                  label={'Email'}
                  name={'email'}
                  value={agent?.email}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                  required={true}
                  type='email'
                />

                <TextField
                  label={'Mobile No'}
                  name={'mobileNo'}
                  value={agent?.mobileNo}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                  required
                />

                <TextField
                  label={'Tel No'}
                  name={'telNo'}
                  value={agent?.telNo}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />

                <TextField
                  label={'Tin'}
                  name={'tin'}
                  value={agent?.tin}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />
              </CardContent>
            </Card>
          </div>
          <div className='mx-8 my-4'>
            <Card>
              <CardHeader title={'Address'} />
              <CardContent className='grid-cols-2 gap-4 grid'>
                <TextField
                  className='col-span-2'
                  label={'Address'}
                  name={'address'}
                  value={agent?.address}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />

                <TextField
                  label={'City'}
                  name={'city'}
                  value={agent?.city}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />

                <TextField
                  label={'Country'}
                  name={'country'}
                  value={agent?.country}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />

                <TextField
                  label={'Postcode'}
                  name={'postcode'}
                  value={agent?.postcode}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />

                <StateSelectField
                  onChange={onSelectChange}
                  label='State'
                  name='state'
                  disabled={disabled}
                  value={agent?.state}
                />
              </CardContent>
            </Card>
          </div>
          <div className='mx-8 my-4'>
            <Card>
              <CardHeader title={'Company Information'} />
              <CardContent className='grid-cols-2 gap-4 grid'>
                <TextField
                  label={'Company Name'}
                  name={'companyName'}
                  value={agent?.companyName}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />

                <TextField
                  label={'Company Reg No'}
                  name={'companyRegNo'}
                  value={agent?.companyRegNo}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                />
              </CardContent>
            </Card>
          </div>
          <div className='mx-8 my-4'>
            <Card>
              <CardHeader title={'Bank Information'} />
              <CardContent className='grid-cols-2 gap-4 grid'>
                <BankSelectField
                  onChange={onSelectChange}
                  label='Bank Name'
                  name='bankName'
                  disabled={disabled}
                  value={agent?.bankName}
                  required={true}
                />

                <TextField
                  label={'Bank Acc No'}
                  name={'bankAccNo'}
                  value={agent?.bankAccNo}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                  required
                />
                <TextField
                  className='col-span-2'
                  label={'Bank Beneficial Name'}
                  name={'bankBeneficialName'}
                  value={agent?.bankBeneficialName}
                  InputLabelProps={{ shrink: true }}
                  onChange={onTextChange}
                  disabled={disabled}
                  required
                />
              </CardContent>
            </Card>
          </div>
          <div className='mx-8 my-4 space-x-3 text-right'>
            {userProfileService.isManager() && (
              <Button
                color={'default'}
                variant={'outlined'}
                onClick={() => setIsChangeSuperiorOpen(true)}
              >
                Update Superior
              </Button>
            )}
            <Button
              color={'secondary'}
              variant={'outlined'}
              disabled={disabled}
              onClick={resetAgent}
            >
              RESET
            </Button>
            <Button
              color={'primary'}
              variant={'contained'}
              type={'submit'}
              disabled={disabled}
            >
              SUBMIT
            </Button>
          </div>
        </form>
      </TabPanel>
      <TabPanel value={PageTabs.COMMISSIONS.key}>
        {UserRole.isAgent(agent?.role) && (
          <div>
            {pageState.haveUnsetPercentage && (
              <Alert severity='warning'>
                <div>Have unset commission</div>
                <div>
                  Please set to to avoid 0 commission when complete order
                </div>
              </Alert>
            )}
            {Object.entries(pageState.commissions).map(
              ([categoryName, commissions]) => {
                return (
                  <CommissionAccordionComponent
                    key={categoryName}
                    commissions={commissions}
                    categoryName={categoryName}
                  />
                )
              }
            )}
          </div>
        )}
      </TabPanel>

      <TabPanel value={PageTabs.SUB_AGENT.key}>
        <SubAgentTreeComponent user={agent} />
      </TabPanel>
      <TabPanel value={PageTabs.WITHDRAWALS.key}>
        {agent && <AgentWithdrawalTab user={agent} />}
      </TabPanel>
      <TabPanel value={PageTabs.CLAIMS.key}>
        {agent && <AgentClaimTab user={agent} />}
      </TabPanel>
      <TabPanel value={PageTabs.ATTACHMENTS.key}>
        {agent && <AgentAttachmentContainer agent={agent} />}
      </TabPanel>
      <ChangeSuperiorDialog
        open={isChangeSuperiorOpen}
        agent={agent}
        onDialogClose={() => setIsChangeSuperiorOpen(false)}
        onSuccess={() => {
          console.log('REFRESH')
          setRefresh(!refresh)
        }}
      />
    </TabContext>
  )
}

export default AgentDetailPage

const AgentAttachmentContainer = (props: { agent: UserBean }) => {
  const dispatch = useAppDispatch()
  const [files, setFiles] = useState<Record<string, File[]>>({})

  const { attachmentVerifiedBy, attachmentVerifiedDate } = props.agent

  const [response, setResponse] = useState<UserProfileAttachmentBean[]>([])
  const fetchAttachment = useCallback(() => {
    UserProfileAttachemntService.getUserProfileAttachmentByUserId(
      props.agent.id
    )
      .then(resp => resp.data)
      .then(setResponse)
  }, [props.agent.id])

  useEffect(fetchAttachment, [fetchAttachment])

  const onSuccessUpload = () => {
    setFiles({})
    fetchAttachment()
  }
  const orderAttachmentBeans: Record<
    string,
    UserProfileAttachmentBean[]
  > = useMemo(() => {
    return [OrderUploadFileKey.MY_KAD, OrderUploadFileKey.SSM].reduce(
      (pre, key) => {
        return {
          ...pre,
          [key]: (response || []).filter(o => o.category === key),
        }
      },
      {}
    )
  }, [response])

  return (
    <React.Fragment>
      {attachmentVerifiedBy && (
        <div className='p-2'>
          Verified By: {attachmentVerifiedBy} at{' '}
          {DateUtils.toDateString(attachmentVerifiedDate)}
        </div>
      )}
      <UserProfileAttachmentTable
        attachmentBeans={orderAttachmentBeans}
        files={files}
        onChange={setFiles}
      />
      <div className='mt-2' />
      <Button
        variant={'contained'}
        color={'primary'}
        disabled={!Object.entries(files).length}
        onClick={() => {
          const entries = Object.entries(files)
          const promises = entries.map(([key, fileList]) => {
            return UserProfileAttachemntService.createUserProfileAttachment(
              props.agent.id.toString(),
              fileList,
              key
            )
          })

          dispatch(LoadingAction.open('Uploading Attachment...'))
          Promise.all(promises)
            .catch(catchErrorWithDispatch(dispatch))
            .finally(() => {
              dispatch(LoadingAction.close())
              onSuccessUpload()
            })
        }}
      >
        UPLOAD FILE
      </Button>
    </React.Fragment>
  )
}
